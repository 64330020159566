$main-color: #59fcf8;
$white: #f7f8f7;
$black: #000002;
$primary_bg: #121212;
$secondary_bg: #0b0c10;

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  scroll-behavior: smooth;
  /* scroll-padding-top:80px; */
}

a {
  color: inherit;
  text-decoration: none;
}

body {
  margin: 0;
}

main {
  // color: $main-color;
  font-family: "Poppins", sans-serif;
}

@media (prefers-color-scheme: dark) {
  html {
    color-scheme: dark;
  }
}

::-webkit-scrollbar {
  width: 3px;
  height: 3px;
}

::-webkit-scrollbar-thumb {
  background-color: $main-color;
  border-radius: 10px;
}

::-webkit-scrollbar-track {
  border-radius: 10px;
}

.main-color {
  color: $main-color;
}

.heading {
  display: flex;
  align-items: center;
  column-gap: 35px;

  .heading-arrow {
    width: 70px;
    height: 2px;
    background-color: $main-color;
    border-radius: 10px;
  }

  h2 {
    font-size: 50px;
    font-weight: 900;
  }
}

main {
  background-color: $black;
  color: $white;

  .header-hero {
    background: url("./assets/images/hero_bg.jpg") no-repeat top;

    .header {
      height: auto;
      padding: 10px 140px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: 80px;

      img {
        height: 100%;
      }

      #google_translate_element {
        // display: none;
        .skiptranslate {
          * {
            // display: none;
          }
          div {
            // display: flex;
            .goog-te-combo,
            select {
              // display: flex;
              padding: 10px;
              background-color: $primary_bg;
              color: $white;
              border: 2px solid $main-color;
              option {
              }
            }
          }
        }
      }

      .toggle {
        display: none;
      }
      .sidebar {
        display: none;
      }

      ul {
        display: flex;
        justify-content: space-around;
        column-gap: 20px;
        align-items: center;
        list-style-type: none;
        text-transform: uppercase;
        font-size: 14px;
        font-weight: 500;

        li {
          transition: 0.3s;

          &:hover {
            color: $main-color;
            transform: translateY(-3px);
          }
        }
      }
    }

    #hero {
      padding: 10px 140px;
      min-height: 100vh;
      height: auto;
      display: grid;
      place-content: center;
      text-align: center;
      text-transform: uppercase;

      .hero-sub-heading {
        font-size: 14px;
        font-weight: 600;
        letter-spacing: 3px;
        color: $main-color;
      }

      .hero-main-heading {
        font-size: 90px;
        font-weight: 900;
        line-height: 100px;
        letter-spacing: 3px;

        p {
          color: $main-color;
          display: flex;
          justify-content: center;
          column-gap: 10px;

          div {
            cursor: pointer;
            transition: 0.2s;
            animation: letters_jump 800ms linear infinite alternate;

            &:hover {
              transform: translateY(-15%) rotateZ(-20deg);
              text-shadow: 0px 0px 5px $main-color;
              animation: none;
              animation-delay: 0ms;
            }
          }
        }
      }

      .hero-button {
        font-size: 24px;
        margin: 15px 0px;
        padding: 10px 25px;
        border: 2px solid $main-color;
        width: max-content;
        text-align: center;
        justify-self: center;
        font-weight: 900;
        cursor: pointer;
        transition: 0.3s;

        &:hover {
          background-color: $main-color;
          color: $black;
        }
      }

      .float-button-section {
        position: fixed;
        bottom: 0;
        right: 0;
        margin: 50px 20px;

        .float-control {
          display: flex;
          column-gap: 10px;
          cursor: pointer;
          transition: 0.3s;

          &:hover {
            transform: scale(0.95);
            opacity: 0.9;
          }

          .float-message {
            text-transform: capitalize;
            font-size: 10px;
            color: $primary_bg;
            background-color: $white;
            border-radius: 3px;
            display: grid;
            place-content: center;
            padding: 0px 10px;
            opacity: 1;
            transition: 0.2s;
          }

          .inactive {
            opacity: 0;
          }
        }

        .float-socials {
          display: flex;
          flex-direction: column;
          align-items: flex-end;
          row-gap: 15px;
          margin: 15px 0px;

          .float-buttons {
            width: 60px;
            position: relative;
            transform: scale(1);
            transition: 0.2s;
          }

          .inactive {
            transform: scale(0);
            // position: fixed;
          }
        }

        .float-buttons {
          padding: 10px;
          background-color: $main-color;
          color: $primary_bg;
          border-radius: 50%;
          aspect-ratio: 1/1;
          display: grid;
          place-items: center;
          cursor: pointer;
          transition: 0.3s;

          &:hover {
            transform: scale(0.95);
            opacity: 0.9;
          }
        }
      }
    }
  }

  .qualities {
    padding: 10px 140px;
    // margin: 80px 0px;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;

    .quality {
      margin: 20px;
      padding: 30px;
      background-color: $primary_bg;
      border: 1px solid $primary_bg;
      aspect-ratio: 1/1;
      display: flex;
      flex-direction: column;
      justify-content: center;
      cursor: pointer;
      transition: 0.3s;

      &:hover {
        border: 1px solid $main-color;
      }

      h2 {
        font-weight: 900;
        text-transform: uppercase;
      }

      .line {
        width: 30%;
        background-color: $main-color;
        height: 5px;
        border-radius: 10px;
        margin: 30px 0px;
      }

      p {
        font-weight: 400;
        font-size: 15px;
        letter-spacing: 2px;
        opacity: 0.9;
      }
    }
  }
  .bordered {
    border: 1px solid $main-color !important;
  }

  #services {
    padding: 80px 140px;
    background-color: $secondary_bg;

    .services {
      margin: 20px 0px;
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      gap: 40px;

      .service {
        aspect-ratio: 1/1;
        // margin: 20px;
        padding: 30px;
        background-color: $primary_bg;
        border-bottom: 5px solid $white;
        aspect-ratio: 1/1;
        display: flex;
        flex-direction: column;
        justify-content: center;
        cursor: pointer;
        transition: 0.3s;

        &:hover {
          border-bottom: 35px solid $main-color;
        }

        h1 {
          font-size: 40px;
          color: $main-color;
        }

        h2 {
          margin: 20px 0px;
          text-transform: uppercase;
        }

        p {
          font-weight: 400;
          font-size: 15px;
          letter-spacing: 2px;
          opacity: 0.9;
        }
      }

      .shaded {
        background-color: $main-color;
        color: $primary_bg;
        border-bottom: 5px solid $white;

        &:hover {
          border-bottom: 35px solid $white;
        }

        h1 {
          color: $primary_bg;
        }
      }
    }

    .steps-services {
      margin-top: 40px;
      // font-size: 3rem;

      .steps {
        margin: 20px 0px;
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        gap: 10px;

        .step {
          display: grid;
          place-items: center;
          text-align: center;
          aspect-ratio: 2/1;
          // align-items: stretch;
          // border-bottom: 2px solid $main-color;

          h1 {
            display: grid;
            place-items: center;
            font-size: 3rem;
            width: 80px;
            aspect-ratio: 1/1;
            // background-color: $primary_bg;
            color: $main-color;
            border: 2px solid $main-color;
            border-radius: 50%;
            margin: 10px 0px;
          }

          p {
            color: $white;
            letter-spacing: 2px;

            a {
              color: $main-color;
            }
          }
        }
      }

      .addresses {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-wrap: wrap;
        gap: 15px;

        aside {
          display: flex;
          align-items: center;
          column-gap: 10px;
          flex-wrap: nowrap;
          cursor: pointer;

          &:hover {
            opacity: 0.7;
          }
        }
      }
    }
  }

  #about {
    padding: 80px 140px;

    .about {
      margin-top: 15px;
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;

      .details {
        overflow: hidden;
        margin: 20px;
        padding: 30px;
        background-position: center;
        background-repeat: no-repeat;
        border: 1px solid $primary_bg;
        aspect-ratio: 1/1;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        cursor: pointer;
        transition: 0.3s;

        &:hover {
          h2 {
            transform: translateY(0);
          }
          // border: 1px solid $main-color;
          p {
            opacity: 0.9;
          }
        }

        h2 {
          transition: 0.2s;
          font-weight: 900;
          text-transform: uppercase;
          transform: translateY(100%);
        }

        p {
          background-color: $primary_bg;
          padding: 10px;
          font-weight: 400;
          font-size: 15px;
          letter-spacing: 2px;
          transition: 0.3s;
          opacity: 0;
        }
      }
    }
  }

  #testimonials {
    padding: 80px 140px;
    background-color: $primary_bg;

    .testimonials {
      margin: 20px 0px;

      .testimony {
        margin: 20px;
        padding: 30px;
        background-color: $secondary_bg;
        border: 1px solid $secondary_bg;
        aspect-ratio: 1/1;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        transition: 0.3s;

        &:hover {
          border: 1px solid $main-color;
        }

        .image {
          width: 80px;
          height: 80px;
          overflow: hidden;
          border-radius: 50%;
          justify-self: center;

          img {
            width: 100%;
            height: 100%;
          }
        }

        h3,
        h4 {
          font-weight: 400;
          text-transform: uppercase;
        }

        p {
          margin-top: 10px;
          font-weight: 400;
          font-size: 15px;
          letter-spacing: 2px;
          opacity: 0.9;
        }
      }
    }
  }

  #contact {
    padding: 100px 140px;
    padding-bottom: 0px;

    .footer-section {
      display: grid;
      grid-template-columns: repeat(4, 25%);
      gap: 40px;

      .footer-about {
        overflow: hidden;

        img {
          width: 70%;
          margin-bottom: 30px;
        }

        p {
          font-weight: 400;
          font-size: 12px;
          letter-spacing: 2px;
          opacity: 0.9;
        }
      }

      .footer-normal-sub {
        font-weight: 400;

        h3 {
          margin-bottom: 30px;
        }

        .footer-list-item {
          display: flex;
          column-gap: 20px;
          margin: 40px 0px;
          cursor: pointer;
          transition: 0.2s;

          &:hover {
            color: $main-color;
          }

          .bullet {
            color: $main-color;
          }
        }
      }
    }
  }
}

@keyframes letters_jump {
  to {
    transform: translateY(-10%);
  }
}
