$main-color: #59fcf8;
$white: #f7f8f7;
$black: #000002;
$primary_bg: #121212;
$secondary_bg: #0b0c10;
@media (max-width: 520px) {
  .heading {
    display: flex;
    align-items: center;
    column-gap: 15px;
    margin: 10px 0px;

    .heading-arrow {
      width: 35px;
      height: 2px;
      background-color: $main-color;
      border-radius: 10px;
    }

    h2 {
      font-size: 25px;
      font-weight: 900;
    }
  }

  main {
    overflow: hidden;
    section {
      padding: 15px !important;
    }

    .header-hero .header {
      padding: 15px !important;

      ul {
        display: none !important;
      }

      .toggle {
        display: block !important;
        cursor: pointer;
      }

      .sidebar {
        position: fixed;
        z-index: 99;
        width: 100vw;
        height: 100vh;
        top: 0;
        left: 100vw;
        background-color: $primary_bg;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        transition: 0.3s;

        h1 {
          cursor: pointer;
          color: $main-color;
        }

        ul {
          margin-top: 40px;
          display: flex !important;
          flex-direction: column;
          row-gap: 40px;
          font-weight: 900;
          font-size: 20px;
        }
      }

      .active {
        left: 0;
      }
    }

    #hero {
      min-height: 80vh !important;
      height: auto;
      .hero-main-heading {
        font-size: 45px !important;
        line-height: 55px !important;

        p {
          font-size: 50px;
        }
      }

      .hero-button {
        font-size: 16px !important;
      }
    }

    .qualities {
      padding: 10px !important;
      grid-template-columns: 1fr !important;

      .quality {
        h2 {
          font-size: 20px;
        }

        .line {
          margin: 15px 0px;
        }
      }
    }

    #services {
      padding: 30px 15px !important;
      margin-top: 50px;
      .services {
        grid-template-columns: 1fr !important;
        .service {
          margin: 10px 0px;
          padding: 15px;
          aspect-ratio: 2/1 !important;

          h1 {
            font-size: 30px !important;
          }

          h2 {
            font-size: 20px !important;
            margin: 15px 0px;
          }
        }
      }

      .steps-services {
        h2 {
          font-size: 18px;
          text-align: center;
        }

        .steps {
          grid-template-columns: 1fr;

          .step {
            aspect-ratio: 5/2;

            p {
              font-size: 14px;
            }
          }
        }

        .addresses {
          font-size: 12px;
          justify-content: flex-start;
        }
      }
    }

    #about {
      padding: 30px 15px !important;
      .about {
        margin-top: 10px;
        grid-template-columns: 1fr !important;

        .details {
          margin: 15px 0px;

          h2 {
            transform: translateY(0) !important;
          }

          p {
            opacity: 0.9 !important;
          }
        }
      }
    }

    #testimonials {
    }

    #contact {
      padding: 90px 15px !important;
      padding-bottom: 0px !important;
      .footer-section {
        grid-template-columns: 1fr !important;
        gap: 10px;

        .footer-about {
          margin: 15px 0px;

          img {
            width: 100%;
          }
        }

        .footer-normal-sub {
          h3 {
            // color: $main-color;
            text-decoration: underline;
            text-transform: uppercase;
            margin-bottom: 15px !important;
          }
          .footer-list-item {
            column-gap: 15px;
            margin: 20px 0px;
          }
        }
      }
    }
  }
}
